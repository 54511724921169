<template>
    <div class="form_ct_box" v-loading.fullscreen.lock="fullscreenLoading">
        <div class="flex cofirm_box">
            <button class="cancel" @click="$router.push('/user/pub_media/pubWeMedia')">
                取消
            </button>
            <button v-if="!$route.query.id" class="affirm" @click="addMedia">
                确认
            </button>
            <button v-else class="affirm" @click="updateFn">修改</button>
        </div>
        <div class="flex ct_box">
            <div class="ct_left_box">
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p">所属平台：</p>
                    <el-select v-model="form.gateway.title" @change="gatewayFn" placeholder="请选择所属平台">
                        <el-option v-for="(item, index) in gatewayList" :key="index" :value="item.title">
                        </el-option>
                    </el-select>
                    <add_platform  category_id="2"  />
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p">账户名称：</p>
                    <el-input v-model="form.title" placeholder="请输入账户名称"></el-input>
                </div>
                <upLoadImg title="媒介logo" :imgUrl="form.logo" @getImg="getLogo" />
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p">行业类型：</p>
                    <el-select v-model="form.platform.title" @change="plat" placeholder="请选择行业类型">
                        <el-option v-for="(item, index) in platList" :key="index" :value="item.title">
                        </el-option>
                    </el-select>
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p">媒介价：</p>
                    <el-input onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                        oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
                        v-model="form.price" placeholder="请输入价格"></el-input>
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p">案例链接：</p>
                    <el-input v-model="form.link" placeholder="请输入http:/或https:/完整地址"></el-input>
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p">粉丝数：</p>
                    <el-input v-model="form.fans_num" placeholder="请输入http:/或https:/完整地址"></el-input>
                </div>
                <upLoadImg title="粉丝量截图" :imgUrl="form.fans_image_reveal" @getImg="getImg" />
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p">平均浏览量：</p>
                    <el-input v-model="form.views" placeholder="请输入浏览量"></el-input>
                </div>
                <!-- <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p">截稿时间：</p>
                    <el-select v-model="form.timer.title" @change="timerFn" placeholder="选择截稿时间">
                        <el-option v-for="(item, index) in timerList" :key="index" :value="item.title">
                        </el-option>
                    </el-select>
                </div> -->
            </div>
            <div class="ct_right_box">
                <!-- <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p2">出稿速度：</p>
                    <el-radio v-model="form.draft_speed" :label="0">暂无</el-radio>
                    <el-radio v-model="form.draft_speed" :label="1">1小时</el-radio>
                    <el-radio v-model="form.draft_speed" :label="2">2小时</el-radio>
                    <el-radio v-model="form.draft_speed" :label="3">3小时</el-radio>
                    <el-radio v-model="form.draft_speed" :label="4">4小时</el-radio>
                    <el-radio v-model="form.draft_speed" :label="5">当天</el-radio>
                    <el-radio v-model="form.draft_speed" :label="6">次日</el-radio>
                </div> -->
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p2">可发联系方式：</p>
                    <el-radio v-model="form.is_pub_contact" :label="0">否</el-radio>
                    <el-radio v-model="form.is_pub_contact" :label="1">是</el-radio>
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p2">是否V认证：</p>
                    <el-radio v-model="form.is_v_auth" :label="0">否</el-radio>
                    <el-radio v-model="form.is_v_auth" :label="1">是</el-radio>
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p2">是否官方：</p>
                    <el-radio v-model="form.Vauthentication" :label="0">否</el-radio>
                    <el-radio v-model="form.Vauthentication" :label="1">是</el-radio>
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p2">是否可发内链：</p>
                    <el-radio v-model="form.is_pub_link" :label="0">否</el-radio>
                    <el-radio v-model="form.is_pub_link" :label="1">是</el-radio>
                </div>
                <specialText />

                <!-- <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p2">可加配图：</p>
                    <el-radio v-model="form.is_pub_image" :label="0">否</el-radio>
                    <el-radio v-model="form.is_pub_image" :label="1">是 </el-radio>
                </div> -->
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p2">可加视频：</p>
                    <el-radio v-model="form.is_pub_video" :label="0">否</el-radio>
                    <el-radio v-model="form.is_pub_video" :label="1">是 </el-radio>
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p2">周末能否发稿：</p>
                    <el-radio v-model="form.is_pub_weekend" :label="0">否</el-radio>
                    <el-radio v-model="form.is_pub_weekend" :label="1">是 </el-radio>
                </div>

                <div class="flex" style="">
                    <p class="title_p2">备注：</p>
                    <el-input type="textarea" :rows="2" placeholder="有修改或其他问题请填写备注，免经济纠纷" v-model="form.remark">
                    </el-input>
                </div>
                <p @click="mediumUpload" class="multiple">
                    您有多个媒体?请点击此处
                </p>
            </div>
        </div>
        <!-- 批量上传 -->
        <el-dialog title="批量上传媒体" width="35%" :append-to-body="true" :visible.sync="mediumBox" :show-close="false">
            <div style="justify-content: center;align-items: center;text-align: center;" class="flex">
                <el-upload class="upload-demo" accept=".xlsx" :headers="headerObj" drag action="/api/common/upload"
                    :on-success="Successxlsx" :before-remove="handleRemove">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">点击上传</div>
                </el-upload>
            </div>

            <div class="flex" style="justify-content: center;margin-top: 1rem;">
                <a class="multiple_a" target="_blank" href="/medium.xlsx">下载模板</a>

                <button class="upLoad_but" @click.stop="upLoadXlsx">确认上传</button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import add_platform from '@/components/add_platform'
export default {
    computed: {
        ...mapState(['active'])
    },
    components:{
        add_platform
    },
    mounted() {
        this.$store.state.active = 2
        this.curlGet('/api/medium/portal', {}).then((res) => {
            // console.log(res);
            if (res.data.code) {
                if (res.data.code) {
                    for (const i in res.data.data[this.$store.state.active]) {
                        this.gatewayList.push({
                            id: i,
                            title: res.data.data[this.$store.state.active][i],
                        })
                    }
                }
            }
        })
        // 请求行业类型列表
        this.curlGet('/api/medium/industry').then((res) => {
            if (res.data.code) {
                for (const i in res.data.data[this.$store.state.active]) {
                    this.platList.push({
                        id: i,
                        title: res.data.data[this.$store.state.active][i],
                    })
                }
            }
        })
        for (let i = 10; i < 25; i++) {
            this.timerList.push({
                id: i,
                title: `${i}:00`,
            })
        }
        if (this.$route.query.id) {
            this.curlGet('/api/users/medium/detail', {
                id: this.$route.query.id,
            }).then((res) => {
                console.log(res)
                if (res.data.code) {
                    let data = res.data.data
                    this.gatewayList.forEach((item, index) => {
                        let data = res.data.data
                        if (data.portal_id == item.id) {
                            this.form.gateway = {
                                id: item.id,
                                title: item.title,
                            }
                        }
                    })
                    this.platList.forEach((item, index) => {
                        if (data.industry_classify_id == item.id) {
                            this.form.platform = {
                                id: item.id,
                                title: item.title,
                            }
                        }
                    })
                    this.timerList.forEach((item, index) => {
                        if (data.stop_draft_time == item.id) {
                            this.form.timer = {
                                id: item.id,
                                title: item.title,
                            }
                        }
                    })
                    this.gateway = data.portal_id
                    this.form.title = data.title //标题
                    this.form.logo_reveal = data.logo //展示logo
                    this.form.log = data.logo // 向后端发送logo
                    this.form.price = data.medium_price //价格
                    this.form.link = data.case_url //案例链接
                    this.draft_speed = data.draft_speed
                    this.form.fans_num = data.fans_num //粉丝数
                    this.form.views = data.views //浏览数
                    this.form.is_pub_video = data.is_pub_video //可发视频
                    this.form.is_pub_contact = data.is_pub_contact //可发联系方式
                    this.form.is_pub_image = data.is_pub_image, //是否可发图文
                        this.form.is_pub_weekend = data.is_pub_weekend //周末是否可发
                    this.form.is_v_auth = data.is_v_auth //是否认证
                    this.form.is_pub_link = data.is_pub_link //可发链接
                    this.form.fans_image = data.fans_image
                    this.form.remark = data.remark //备注
                }
            })
        }
    },
    data() {
        return {
            gatewayList: [], //门户类型
            platList: [], //行业类型
            timerList: [], //地区类型
            value: '',
            dialogVisible: false,
            form: {
                logo_reveal:'',
                gateway: {}, //门户类型
                platform: {}, //行业
                fans_num: '', //粉丝数
                views: '', //浏览数
                title: '', //媒介名称
                timer: {}, //截稿时间
                draft_speed: 0,
                is_v_auth: 0, //是否为v认证  
                Vauthentication: 0, // 大V认证
                is_pub_video: 0, //是否可发视频
                is_pub_weekend: 0, //是否周末可发
                is_pub_link: 0, //是否可加链接
                is_pub_contact: 0, //可否加联系方式
                is_pub_image: 0, //可加配图
                price: undefined, //价格
                remark: '', // 备注
                link: '', //链接
                fans_image: '',
                fans_image_reveal: '',
                mediaUrl: '',
            },
            headerObj: {
                token: this.$user_info.token,
            },
            fullscreenLoading: false, //加载
            addbox: false,
            gatewayForm: {
                gatewayTitle: '',
                gatewayUrl: '',
            },
            mediumBox: false,
        }
    },
    methods: {
        getImg(url,fullurl) {
            console.log(url);
            this.form.fans_image = url
            this.form.fans_image_reveal = fullurl
        },
        getLogo(url, fullurl) {
            this.form.logo = url
            this.form.logo_reveal = fullurl
        },
        mediumUpload() {
            this.mediumBox = true
        },
        // 批量上传媒体
        Successxlsx(res, file) {
            console.log(res, file)
            this.form.mediaUrl = res.data.url
        },
        upLoadXlsx() {
            this.curlGet('/api/users/medium/import', {
                file: this.form.mediaUrl
            }).then(res => {
                console.log(res);
                if (res.data.code) {
                    this.$message({
                        message: '上传成功,待审核',
                        type: 'success'
                    });
                    this.form.mediaUrl = ''
                    return this.mediumBox = false
                } else {
                    this.$message.error('上传失败');
                }
            })
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
            this.form.mediaUrl = ''
        },
        gatewayFn(val) {
            let resultArr = this.gatewayList.filter((item) => {
                return item.title === val
            })
            console.log(resultArr)
            this.form.gateway = resultArr[0]
        },
        // 获取行业id
        plat(val) {
            let resultArr = this.platList.filter((item) => {
                return item.title === val
            })
            console.log(resultArr)
            this.form.platform = resultArr[0]
        },
        // 获取截稿时间id
        timerFn(val) {
            let resultArr = this.timerList.filter((item) => {
                return item.title === val
            })
            console.log(resultArr)
            this.form.timer = resultArr[0]
        },
        // 添加媒体
        addMedia() {
            this.$confirm('确认提交吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.curlPost('/api/users/medium/add', {
                        category_id: this.$store.state.active, // 导航id
                        portal_id: this.form.gateway.id, // 门户(所属平台)类型
                        title: this.form.title, // 标题
                        medium_price: this.form.price, // 价格
                        case_url: this.form.link, // 案例链接
                        industry_classify_id: this.form.platform.id, //媒体行业类型
                        fans_num: this.form.fans_num, //粉丝数
                        views: this.form.views, //浏览数
                        draft_speed: this.form.includeType, // 出稿速度
                        is_pub_contact: this.form.is_pub_contact, //可发联系方式
                        remark: this.form.remark, //备注
                        logo: this.form.logo, //logo
                        is_official: this.form.Vauthentication, // 是否大V认证
                        is_pub_video: this.form.is_pub_video, //是否可发视频
                        is_pub_weekend: this.form.is_pub_weekend, //是否周末可发
                        is_pub_link: this.form.is_pub_link, //是否可发链接式
                        is_v_auth: this.form.is_v_auth,
                        is_pub_image: this.form.is_pub_image,
                        stop_draft_time: this.form.timer.id, //截稿时间
                    }).then((res) => {
                        console.log(res)
                        if (res.data.code) {
                            this.$message({
                                type: 'success',
                                message: '添加成功,待审核',
                            })
                            this.$store.state.active = 1
                            return this.$router.push('/user/pub_media/pubWeMedia')
                        } else {
                            return this.$message({
                                type: 'warning',
                                message: res.data.msg,
                            })
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消',
                    })
                })
        },
        // 修改媒体
        updateFn() {
            this.$confirm('确认修改吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.curlPost('/api/users/medium/add', {
                        id: this.$route.query.id,
                        category_id: this.$store.state.active, // 导航id
                        portal_id: this.form.gateway.id, // 门户(所属平台)类型
                        title: this.form.title, // 标题
                        medium_price: this.form.price, // 价格
                        case_url: this.form.link, // 案例链接
                        industry_classify_id: this.form.platform.id, //媒体行业类型
                        fans_num: this.form.fans_num, //粉丝数
                        views: this.form.views, //浏览数
                        draft_speed: this.form.includeType, // 出稿速度
                        is_pub_contact: this.form.is_pub_contact, //可发联系方式
                        remark: this.form.remark, //备注
                        logo: this.form.logo, //logo
                        is_official: this.form.Vauthentication, // 是否大V认证
                        is_pub_video: this.form.is_pub_video, //是否可发视频
                        is_pub_weekend: this.form.is_pub_weekend, //是否周末可发
                        is_pub_link: this.form.is_pub_link, //是否可发链接
                        is_v_auth: this.form.is_v_auth,
                        is_pub_contact: this.form.is_pub_contact, //是否加联系方式
                        is_pub_image: this.form.is_pub_image,
                        stop_draft_time: this.form.timer.id, //截稿时间
                    }).then((res) => {
                        console.log(res)
                        if (res.data.code) {
                            this.$message({
                                type: 'success',
                                message: '添加成功,待审核',
                            })
                            this.$store.state.active = 1
                            return this.$router.push('/user/pub_media/pubWeMedia')
                        } else {
                            return this.$message({
                                type: 'warning',
                                message: res.data.msg,
                            })
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消',
                    })
                })
        },
        // 添加平台
        addPlat() {
            this.curlPost('api/users/medium/apply_portal', {
                category_id: 2,
                title: this.gatewayForm.gatewayTitle,
                url: `http://${this.gatewayForm.gatewayUrl}`,
            }).then((res) => {
                if (res.data.code) {
                    this.addbox = false
                    return this.$message({
                        type: 'success',
                        message: '已上传,待审核',
                    })
                } else {
                    return this.$message({
                        type: 'warning',
                        message: res.data.msg,
                    })
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/AddResource';
</style>